import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import moment from "moment";
import { RiBattery2ChargeFill } from "react-icons/ri";

const MapContainers = ({ lat, lng, fio, battery, time }) => {
  const [map, setMap] = useState(null);
  if (map) {
    map.flyTo([lat, lng]);
  }
  return (
    <MapContainer
      whenCreated={setMap}
      center={[lat, lng]}
      zoom={15}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {lat == "40.360682" ? null : (
        <Marker position={[lat, lng]}>
          <Popup>
            {fio} нинг<br></br> {time}
            <br></br>
            вақтдаги жойлашуви <br></br>
            <RiBattery2ChargeFill
              style={{ color: "var(--main-color)", verticalAlign: "middle" }}
            ></RiBattery2ChargeFill>
            {battery}% заряди бор эди.
          </Popup>
        </Marker>
      )}
    </MapContainer>
  );
};

export default MapContainers;
