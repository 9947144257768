import React from "react";
import SimplePage from "./Pages/SimplePage";
import { ASOSIY, SIMPLE } from "./PageTypes";
import loginBrand from "../../images/logo512.png";

const Page = ({ type, dataSource }) => {
  let page = "";
  switch (type) {
    case SIMPLE:
      page = <SimplePage searchDataSource={dataSource}></SimplePage>;
      break;
    case ASOSIY:
      page = (
        <div>
          <center>
            <img
              height="400"
              style={{ alignContent: "center" }}
              src={loginBrand}
              alt="logo"
            />
          </center>
        </div>
      );
      break;
    default:
      break;
  }
  return page;
};

export default Page;
