import React, { useEffect } from "react";
import {
  RiAddLine,
  RiCloseLine,
  RiFileExcel2Fill,
  RiHonourFill,
  RiPencilLine,
  RiPrinterFill,
} from "react-icons/ri";
import { Button, message, Popconfirm } from "antd";
import { useState } from "react";
import "./Actions.css";
import {
  setModal,
  getMainTableData,
  setValues,
  setRowId,
} from "../../Actions/AppActions";
import { connect } from "react-redux";
import fetchApi from "../../Functions/fetchApi";
import { useHistory } from "react-router-dom";
const Actions = ({
  setModal,
  rowId,
  data,
  setValues,
  currentPage,
  getMainTableData,
  allData,
  setRowId,
}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    document.addEventListener("keydown", KeyboardEventss);

    return () => {
      document.removeEventListener("keydown", KeyboardEventss);
    };
  }, []);

  const KeyboardEventss = (event) => {
    switch (event.key) {
      case "Insert":
        openAdd();
        break;

      default:
        break;
    }
  };
  const openAdd = () => {
    if (currentPage.defaultValues && currentPage.defaultValues.length > 0) {
      let obj = {};
      currentPage.defaultValues.forEach((d) => {
        obj[d.prop] = d.value;
      });
      setValues(obj);
    }
    setRowId(null);
    setModal(true);
  };

  const { location } = useHistory();
  const openEdit = () => {
    if (rowId) {
      let obj = data.find((d) => d.Id === rowId);
      if (
        location.pathname == "/servis/brendlar" &&
        currentPage.defaultDocument
      ) {
        obj = {
          ...obj,
          document: allData[currentPage.defaultDocument.allData]
            .filter((d) => d[currentPage.defaultDocument.prop] == rowId)
            .map((m) => {
              return { ...m, element_id: m.Id };
            }),
        };
      }
      setValues(obj);
      setModal(true);
    } else {
      message.warning("Qatorni tanlang!");
    }
  };
  const handleDelete = async () => {
    let user_id = null;
    if (
      JSON.parse(window.localStorage.getItem("user")) &&
      JSON.parse(window.localStorage.getItem("user")).id
    ) {
      user_id = JSON.parse(window.localStorage.getItem("user")).id;
    }
    if (rowId) {
      setLoading(true);
      const res = await fetchApi(currentPage.path + "/" + rowId, "DELETE", {
        user_id: user_id,
      });
      if (res && res.status === 200) {
        setRowId(null);
        getMainTableData(currentPage.path);
      }
      setVisible(false);
      setLoading(false);
    } else {
      message.warning("Qatorni tanlang!");
    }
  };

  const history = useHistory();
  const toPrint = () => {
    if (rowId) {
      history.push("/print");
    }
  };
  const toNaklad = () => {
    history.push("/hisobot/naklad");
  };
  return (
    <div style={{ display: "flex" }} className="body_actions">
      <Button onClick={openAdd} type="primary" className="action_btn main-btn">
        <RiAddLine className="action_icon"></RiAddLine> &nbsp;{" "}
        <span className="action_text">Қўшиш</span>
      </Button>
      &nbsp; &nbsp;
      <Button onClick={openEdit} type="primary" className="action_btn edit-btn">
        <RiPencilLine className="action_icon"></RiPencilLine> &nbsp;{" "}
        <span className="action_text">Ўзгартириш</span>
      </Button>
      &nbsp; &nbsp;
      <Popconfirm
        title="Маьлумот ўчирилади!"
        visible={visible}
        onConfirm={handleDelete}
        okButtonProps={{ loading: loading }}
        onCancel={() => setVisible(false)}
      >
        <Button
          icon
          loading={loading}
          className="action_btn delete-btn"
          onClick={() => {
            if (rowId) {
              setVisible(true);
            } else message.warning("Qatorni tanlang!");
          }}
        >
          <RiCloseLine className="action_icon"></RiCloseLine> &nbsp;{" "}
          <span className="action_text">Ўчириш</span>
        </Button>
      </Popconfirm>
      &nbsp; &nbsp;
      {location.pathname == "/sotuv-bolimi" && (
        <>
          <Button
            onClick={toPrint}
            type="primary"
            className="action_btn main-btn"
          >
            <RiPrinterFill className="action_icon"></RiPrinterFill>
          </Button>
          &nbsp; &nbsp;
          <Button
            onClick={toNaklad}
            type="primary"
            className="action_btn main-btn"
          >
            <RiHonourFill className="action_icon"></RiHonourFill>
          </Button>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    rowId: state.app.rowId,
    data: state.app.mainTableData,
    loading: state.app.loading,
    values: state.app.values,
    allData: state.app.allData,
    currentPage: state.app.currentPage,
  };
};

export default connect(mapStateToProps, {
  setModal,
  getMainTableData,
  setValues,
  setRowId,
})(Actions);
