import { RiUserStarFill } from "react-icons/ri";
import { SIMPLE } from "../Component/Sidebar/PageTypes";
import SanaFormat from "../Functions/SanaFormat";
import MapTrigger from "./Components/MapTrigger";
const AsosiyOyna = {
  title: "ASSAID",
  path: "/",
  icon: <RiUserStarFill></RiUserStarFill>,
  type: SIMPLE,
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Ф.И.Ш</div>,
      dataIndex: "fio",
      key: "fio",
      width: "215px",
    },
    {
      title: <div className="table_head">Телефон рақам</div>,
      dataIndex: "telefon",
      key: "telefon",
      width: "145px",
    },
    {
      title: <div className="table_head">Локация</div>,
      align: "center",
      render: (text, record) => <MapTrigger record={record}></MapTrigger>,
      width: "145px",
    },
    {
      title: <div className="table_head">Ўзгарган вақти</div>,
      dataIndex: "time_update",
      key: "time_update",
      width: "145px",
      render: (text, record) => <div>{SanaFormat(text)}</div>,
    },
  ],
  forms: [],
};
export default AsosiyOyna;
